import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router';
import HomePage from './components/HomePage';
import Blog from './components/Blog';
import SinglePost from './components/Blog/singlePost';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import Page from './components/Page';
import GlobalCss from './styles/jss/GlobalCss';
import { Theme } from "./theme";
import { ThemeProvider } from '@mui/material/styles'
import TopBar1 from './parts/TopBar1';
import Footer1 from './parts/Footer1';
import Category from './components/Blog/category';

function App() {
  return (
        <ThemeProvider theme={Theme}>
            <GlobalCss>
                <TopBar1 />
                <div className="mt-5 pt-5">
                    <Routes>
                        <Route path="/register" element={<RegisterPage /> } />
                        <Route path="/login" element={<LoginPage /> } />
                        <Route path="/" element={<HomePage /> } />
                        <Route path="/blog" element={<Blog /> } />
                        <Route path="/blog/:category/" element={<Category />} />
                        <Route path="/blog/:category/:id" element={<SinglePost /> } />
                        <Route path="/:id" element={<Page />} />
                    </Routes>
                </div>
                <Footer1 />
            </GlobalCss>
        </ThemeProvider>
  );
}

export default App;
