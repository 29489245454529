import React from "react";

import { Card, CardContent, Avatar } from "@mui/material";

import Carousel from "../common/Carousel";


const Testimonial1 = ({data}) => {

  const {
    title,
    subtitle,
    testimonials
  } = data;

  return (
    <div className="section" id="testimonial1">
      <div className="container">
        <div className="section__header">
          <h2>{title}</h2>
          <p>
            {subtitle}
          </p>
        </div>

        <Carousel carouselId="testimonial-1">
          {testimonials.map((testimonial, index) => (
            <Card className="px-6 card w-full h-full" key={index}>
              <CardContent className="flex-column justify-between min-h-full">
                <div className="mb-6 mt-4">
                  <img src={testimonial.logo.url} alt="logo" />
                </div>

                <p className="mt-0 mb-16 text-16">
                  "{testimonial.text}"
                </p>

                <div className="flex flex-wrap items-center">
                  <Avatar
                    className="w-48 h-48"
                    src={testimonial.picture.url}
                  />
                  <div className="pl-4">
                    <p className="m-0">
                      <strong>{testimonial.full_name}</strong>
                    </p>
                    <p className="m-0">{testimonial.poisition}</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial1;
