import React, { useEffect, useState } from "react";

import { Grid, Button, Icon } from "@mui/material";
import { makeStyles } from '@mui/styles';

import clsx from "clsx";
import useAxios from "axios-hooks";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  footerSection: {
    "& h4:after": {
      content: '" "',
      position: "absolute",
      bottom: -8,
      left: 0,
      height: 2,
      width: 64,
      background: palette.secondary.main,
    },
  },
}));

const Footer1 = () => {
  const classes = useStyles();
  const [footerData, setFooterData] = useState({});

  const [{ data, loading }] = useAxios(
    process.env.REACT_APP_API+'/options/all'
  )

  useEffect(() =>{
    if(!loading){
      setFooterData(data);
    }
}, [data, loading]);

  return (
    <div className={clsx("bg-light-dark", classes.footerSection)} id="footer1">
      <div className="container">
        <Grid container>
          <Grid item lg={6} md={6} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">About Us</h4>
              <p className="text-inherit">
                {footerData.about_us}
              </p>
              <Button variant="contained" color="secondary">
                <Link to={footerData.contact_button_link} >Contact Us</Link>
              </Button>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">Contact</h4>
              <div className="px-4 my-8 flex items-center mx--4">
                <Icon className="text-secondary">mail</Icon>
                <div className="pl-4">
                  <h5 className="m-0 p-0 text-16">Email</h5>
                  <p className="m-0 p-0 text-inherit">{footerData.footer_email}</p>
                </div>
              </div>
              <div className="px-4 mt-8 flex items-center mx--4">
                <Icon className="text-secondary">location_on</Icon>
                <div className="pl-4">
                  <h5 className="m-0 p-0 text-16">Adress</h5>
                  <p className="m-0 p-0 text-inherit">
                    {footerData.footer_address}
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">Disclaimer</h4>
              <p className="text-inherit">
                {footerData.disclaimer}
              </p>

              <div className="mt-8">
              {data?.footer_social_media.map((singleSocial) => {
                return(
                  <a href={singleSocial.link} className="px-2" key={singleSocial.link}>
                  <img
                    className="h-24 w-24"
                    src={singleSocial.icon.url}
                    alt={singleSocial.icon.alt}
                  />
                </a>
                )
              })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer1;
