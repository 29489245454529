import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import MetaHelmet from '../../helpers/metaTags';
import { useSearchParams } from 'react-router-dom';
import SwitchComponents from '../../helpers/switchComponents';

/**
 * Page with MetaDescriptions and Switch components from ACF
 */
const Page = () => {

    const params = useParams();
    const [parts, setParts] = useState<any[]>([]);
    const [postId, setPostId] = useState<Number>();

    const [searchParams] = useSearchParams();
    const isPreview = searchParams.get('preview');

    const [{ data, loading }] = useAxios(
        isPreview ? process.env.REACT_APP_API+`/wp/v2/preview-changes/${params.id}` : process.env.REACT_APP_API+`/wp/v2/pages?slug=${params.id}&acf_format=standard`
    )

    useEffect(() => {
        if(!loading){
            setPostId(isPreview ? data.post.id : data[0]?.id);
            setParts(isPreview ? data.acf.landing_page : data[0].acf.landing_page);
        }
    }, [data, loading]);

    return(
    <>
        <MetaHelmet postId={postId} />
        <SwitchComponents parts={parts} postId={postId} />
    </>
    );

}

export default Page;
