import React from "react";

import {  Avatar,  Icon, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Carousel from "../common/Carousel";
import clsx from "clsx";

const Testimonial5 = ({data}) => {

  const {
    background,
    testimonials,
    title
  } = data;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={clsx("section")} id="testimonial5" style={{background: `url(${background.url})`,
    backgroundSize: "cover",
    backgroundBlendMode: "multiply",}}>
      <div className="container text-center">
        <h1 className="font-normal text-44 mt-0 text-white mx-auto mb-16">
          {title}
        </h1>
        <Carousel
          bulletColor={theme.palette.primary.contrastText}
          slidesPerView={1}
          spacing={0}
          navigation={false}
          paginationClass="mt-16"
          carouselId="swiper-5"
        >
          {testimonials.map((testimonial, index) => (
            <div className={clsx("mx-auto")} key={index}>
              <div
                className={clsx({
                  "flex justify-center": true,
                  "flex-wrap": isMobile,
                })}
              >
                <Avatar
                  className="w-108 h-108"
                  src={testimonial.picture.url}
                  alt="user"
                />
                <p
                  className={clsx({
                    "text-white": true,
                    "text-left my-0 ml-8": !isMobile,
                  })}
                >
                  {testimonial.testimonial}
                </p>
              </div>
              <div className="flex flex-wrap mt-4 justify-center mb-2">
                {[1, 2, 3, 4, 5].map((i) => (
                  <Icon
                    key={i}
                    className="mx-1"
                    fontSize="small"
                    color="secondary"
                  >
                    star
                  </Icon>
                ))}
              </div>
              <h5 className="inline-block m-0 font-medium text-white">
                {testimonial.name}
              </h5>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial5;
