import React, { useEffect, useState } from "react";
import EditAcfComponents from "./editAcfComponents";
import Intro1 from "../parts/Intro1";
import Intro2 from "../parts/Intro2";
import Intro3 from "../parts/Intro3";
import Services1 from "../parts/Services1";
import Service3 from "../parts/Services3";
import Service4 from "../parts/Services4";
import Service6 from "../parts/Services6";
import Service5 from "../parts/Services5";
import Service7 from "../parts/Services7";
import Portfolio1 from "../parts/Portfolio1";
import Testimonial1 from "../parts/Testimonial1";
import Testimonial5 from "../parts/Testimonial5";
import CallToAction1 from "../parts/CallToAction1";
import Pricing1 from "../parts/Pricing1";
import About1 from "../parts/About1";
import Gallery1 from "../parts/Gallery1";
import WorkProcess1 from "../parts/WorkProcess1";
import Blog1 from "../parts/Blog1";
import Speakers1 from "../parts/Speakers1";
import EventSchedule1 from "../parts/EventSchedule1";
import EventDetails1 from "../parts/EventDetails1";
import Subscription4 from "../parts/Subscription4";
import useAxios from "axios-hooks";
import Test from "../parts/test";

/**
 * Components switch from ACF flexible content
 */
const SwitchComponents = ({ parts, postId }: any) => {
  const [backendUrl, setBackendUrl] = useState<string>("");

  const [{ data: optionsData, loading: optionsLoading }] = useAxios(
    process.env.REACT_APP_API + "/options/all"
  );

  useEffect(() => {
    if (!optionsLoading) {
      setBackendUrl(optionsData.backend_url);
    }
  }, [optionsData, optionsLoading]);

  return (
    <>
      {parts.map((singlePart: any, i: number) => {
        const displayPart = () => {
          switch (singlePart.acf_fc_layout) {
            case "intro":
              return <Intro1 data={singlePart} />;
            case "intro2":
              return <Intro2 data={singlePart} />;
            case "intro3":
              return <Intro3 data={singlePart} />;
            case "services":
              return <Services1 data={singlePart} />;
            case "service3":
              return <Service3 data={singlePart} />;
            case "service4":
              return <Service4 data={singlePart} />;
            case "service5":
              return <Service5 data={singlePart} />;
            case "service6":
              return <Service6 data={singlePart} />;
            case "service7":
              return <Service7 data={singlePart} />;
            case "portfolio":
              return <Portfolio1 data={singlePart} />;
            case "testimonial":
              return <Testimonial1 data={singlePart} />;
            case "testimonial5":
              return <Testimonial5 data={singlePart} />;
            case "call_to_action":
              return <CallToAction1 bg={""} data={singlePart} />;
            case "pricing":
              return <Pricing1 data={singlePart} />;
            case "about1":
              return <About1 data={singlePart} />;
            case "gallery1":
              return <Gallery1 data={singlePart} />;
            case "workprocess1":
              return <WorkProcess1 data={singlePart} />;
            case "blog1":
              return <Blog1 fields={singlePart} />;
            case "speakers1":
              return <Speakers1 data={singlePart} />;
            case "eventschedule1":
              return <EventSchedule1 data={singlePart} />;
            case "eventdetails1":
              return <EventDetails1 data={singlePart} />;
            case "subscription4":
              return <Subscription4 data={singlePart} />;
            case "test":
              return <Test data={singlePart} />;
            default:
              <></>;
          }
        };
        return (
          <div className="my-2" key={i + 1}>
            <EditAcfComponents
              postId={postId}
              backendUrl={backendUrl}
              componentName={singlePart.acf_fc_layout}
            />
            {displayPart()}
          </div>
        );
      })}
    </>
  );
};

export default SwitchComponents;
